import styled from "@emotion/styled";
import { useTheme, css } from "@emotion/react";
import { ReactChildren } from "react";
import { breakpoints, mq } from "../../styles/mq";

interface Props {
	tagline?: string;
	children: ReactChildren | string;
	center?: boolean;
	maxWidth?: string;
	styles?: any;
}

export const H1 = (props: Props) => {
	const theme = useTheme();

	const H1Styled = styled("div")`
		margin: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		> p {
			color: ${theme.red};
			font-size: 1rem;
			font-family: Roboto;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 5px;
			margin: 0s;
			padding: 0;
			margin-bottom: 0;
			${mq(breakpoints.sm)} {
				font-size: 1rem;
			}
		}
		> h1 {
			font-size: 2rem;
			margin: 0;
			padding: 0;
			${mq(breakpoints.sm)} {
				font-size: 3rem;
			}
		}
	`;
	return (
		<H1Styled
			css={css`
				${props.styles};
			`}
		>
			{props.tagline && <p>{props.tagline}</p>}
			<h1>{props.children}</h1>
		</H1Styled>
	);
};
