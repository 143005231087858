import { css, keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "next/link";
import { ReactChildren, useState } from "react";

interface Props {
	children: ReactChildren | string;
	href?: string;
	type?: string;
    styles?: any;
}

const highlight = keyframes`
	0% {

			transform: translateX(105%) rotate(20deg);
		
	}
	100% {
			transform: translateX(-200px) rotate(20deg);
		
}`;

const ButtonStyled = styled.a`
	position: relative;
	display: block;
	width: fit-content;
	padding: 10px 30px;
	border-radius: 25px;
	cursor: pointer;
	border: none;
	outline: none;
	overflow: hidden;

	transition: transform 0.15s cubic-bezier(0.47, 0, 0.75, 0.72);

	&:before {
		content: "";
		position: absolute;
		display: block;
		top: -20px;
		right: 0;
		width: 75px;
		height: 200%;
		transform: translateX(105%) rotate(20deg);
		background: #fff;
		opacity: 0.2;
	}

	&:focus {
		opacity: 0.9;
	}

	&:active {
		opacity: 0.8;
	}

	&:hover {
		overflow: hidden;
		transform: scale(1.05);
	}

	&.animate-true {
		&:before {
			animation: ${highlight} 0.5s 0s both
				cubic-bezier(0.55, 0.06, 0.68, 0.19);
		}
	}
`;

const ButtonText = styled.span`
	color: white;
	font-size: 1.1rem;
	font-family: Panton, Roboto, Helvetica, sans-serif;
	font-weight: 700;
	letter-spacing: 2px;
	background: none;
	outline: none;
	border: none;
`;

export const Button = (props: Props) => {
	const theme = useTheme();

	const [isShown, setIsShown] = useState(false);

	if (props.type === "submit") {
		return (
			<ButtonStyled
				css={css`
					background-color: ${theme.red};
				    ${props.styles};
				`}
				className={`animate-${isShown}`}
				type="submit"
				onMouseEnter={() => setIsShown(true)}
				onAnimationEnd={() => setIsShown(false)}
				as="button"
			>
				<ButtonText>{props.children}</ButtonText>
			</ButtonStyled>
		);
	} else {
		return (
			<ButtonStyled
				href={props.href || ""}
				css={css`
					background-color: ${theme.red};
					position: relative;
					overflow: hidden;
					height: auto;
                    ${props.styles};
				`}
				className={`animate-${isShown}`}
				type="submit"
				onMouseEnter={() => setIsShown(true)}
				onAnimationEnd={() => setIsShown(false)}
			>
				<ButtonText>{props.children}</ButtonText>
			</ButtonStyled>
		);
	}
};
